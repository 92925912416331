/* Override default react-datepicker wrapper */
.date-selector-wrapper {
    @apply w-full;
}

/* Arrows fix */
.react-datepicker__navigation--years::before {
    @apply border-solid border-grayDarker border-t-2 border-r-2;
    @apply h-[9px] w-[9px] left-[11px] absolute block;
    content: '';
}

.react-datepicker__navigation--years-upcoming::before {
    @apply top-[17px] rotate-[315deg];
}

.react-datepicker__navigation--years-previous::before {
    @apply top-[6px] rotate-[135deg];
}
