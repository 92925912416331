@tailwind components;

@layer components {
    .user-text {
        @apply text-body leading-relaxed text-primary;

        -webkit-font-smoothing: antialiased;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @apply mb-2 font-bold text-primary [text-rendering:optimizeLegibility] [word-wrap:break-word];
        }

        h1 {
            @apply text-[30px] leading-9 lg:mb-4 lg:text-h1;
        }

        h2 {
            @apply text-h3 leading-8 lg:text-h2;
        }

        h3 {
            @apply text-h4 leading-7 lg:mb-4 lg:text-h3 lg:leading-8;
        }

        h4 {
            @apply text-h5 leading-[30px] lg:text-h4 lg:leading-6;
        }

        h5 {
            @apply text-[21px] leading-8 lg:text-h5 lg:leading-5;
        }

        h6 {
            @apply text-h6 font-normal leading-6;
        }

        p,
        ul,
        ol,
        li {
            @apply leading-[inherit] text-inherit [font-size:inherit];
        }

        p {
            @apply mb-7 vl:mb-10;
        }

        ul,
        ol {
            @apply mb-7 vl:mb-10;

            ul,
            ol {
                @apply mt-7 vl:mt-10;
            }
        }

        ul {
            @apply pl-4;
            list-style: square;
        }

        ol {
            @apply pl-4;
            list-style: decimal;

            & > li::marker {
                @apply font-bold;
            }
        }

        img {
            @apply block h-auto rounded-none;
        }

        iframe {
            @apply max-w-full;
        }

        .DefaultButton,
        .SmallButton,
        .DefaultButton-Green,
        .SmallButton-Green {
            @apply inline-flex w-auto cursor-pointer content-center items-center border-0 text-center font-medium no-underline outline-0 transition-colors duration-200 ease-defaultTransition;
            @apply min-h-[50px] rounded-md bg-primary py-4 px-10 text-h6 leading-5 text-white hover:bg-[#3b3b3b] hover:text-white lg:text-h5 [&>i]:h-5 [&>i]:w-5 [&>i]:text-white [&>i]:hover:text-white [&>i:first-child]:ml-3 [&>i:last-child:not(:first-child)]:mr-3;
        }

        .SmallButton,
        .SmallButton-Green {
            @apply min-h-[40px] py-[9px] px-8 text-body [&>i]:h-4 [&>i]:w-4 [&>i:first-child]:ml-1 [&>i:last-child:not(:first-child)]:mr-1;
        }

        .DefaultButton-Green,
        .SmallButton-Green {
            @apply bg-green text-white hover:bg-[#004d3a];
        }
    }

    .grapesjs-text {
        div,
        p {
            @apply py-1;
        }

        ul,
        ol {
            @apply grid;
        }

        .row {
            @apply block gap-x-6 w-full lg:flex lg:flex-row lg:flex-wrap;

            & .column {
                @apply flex flex-1 flex-col pt-2 pb-2 first:pl-0 last:pr-0;
            }
        }

        .video {
            @apply relative h-0 overflow-hidden pb-[56.25%];

            iframe {
                @apply absolute left-0 top-0 h-full w-full;
            }
        }

        .gjs-text-with-image-wrapper {
            @apply w-full [word-break:break-word];

            & .clear {
                @apply clear-both p-0;
            }
        }

        .text-with-image {
            @apply mb-4 w-full lg:w-[200px] xl:w-[350px];
        }

        .text-with-image-float-left {
            @apply lg:float-left lg:mr-8;
        }

        .text-with-image-float-right {
            @apply lg:float-right lg:ml-8;
        }

        .text-with-image-float-right.text-with-image-type-outside-layout {
            @apply lg:mr-0 xl:-mr-[200px];
        }

        .text-with-image-float-left.text-with-image-type-outside-layout {
            @apply lg:ml-0 xl:-ml-[200px];
        }

        .image-position-right {
            @apply ml-auto;
        }

        .image-position-left {
            @apply mr-auto;
        }

        .image-position-center {
            @apply m-auto;
        }

        a:has(.image-position-right) {
            @apply !ml-auto !block;
        }

        a:has(.image-position-left) {
            @apply !mr-auto !block;
        }

        a:has(.image-position-center) {
            @apply !m-auto !block;
        }

        .gjs-link-block {
            @apply block h-fit min-h-[50px] w-fit min-w-[50px] p-[5px];
        }
    }

    .gjs-fulfillment-list {
        ul {
            @apply !list-none !pl-0;
        }

        li {
            @apply flex justify-between border-solid border-border py-4 px-5 text-h6 first:border-t-0 vl:border-t vl:border-borderLight;

            & > *:last-child {
                @apply font-bold;
            }

            &:nth-child(odd) {
                @apply border-l bg-grayLight vl:border-l-0;
            }
        }
    }
}
