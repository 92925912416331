@import 'nprogress/nprogress.css';
@import 'react-toastify/dist/ReactToastify.min.css';
@import 'react-datepicker/dist/react-datepicker.css';

@import 'components.css';
@import 'user-text.css';
@import 'product-detail.css';
@import 'toasts.css';
@import 'table.css';
@import 'ms-edge.css';
@import 'date-selector.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    *,
    *::before,
    *::after {
        @apply box-border;
    }

    html {
        @apply h-full;
        -webkit-tap-highlight-color: transparent;
    }

    html,
    body {
        @apply min-h-full;
    }

    body {
        @apply overflow-x-hidden;
    }

    body,
    form,
    input,
    select,
    button,
    p,
    pre,
    dfn,
    address,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img,
    table,
    tr,
    td,
    th,
    textarea {
        @apply font-base text-body text-primary lg:text-body;
        line-height: 1.3;
    }

    address {
        @apply not-italic;
    }

    img:not(.icon):not(.responsive-image) {
        @apply max-w-full;
        image-rendering: -webkit-optimize-contrast;
    }

    td,
    th {
        @apply text-left;
    }

    hr {
        height: 1px;
    }

    dfn {
        font-style: normal;
    }

    table {
        @apply border-collapse;
    }

    p {
        @apply break-words;
        margin-bottom: 10px;
    }

    a {
        @apply cursor-pointer text-grayDark underline outline-none hover:text-primary hover:underline;
    }

    b,
    strong {
        @apply font-bold;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    button {
        @apply focus-visible:outline-none;
    }

    em {
        font-style: italic;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    input[type='search'] {
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }
    }

    @screen vl {
        .menu-gradient {
            background: linear-gradient(to bottom, #222, #222 65px, #fff 65px, #fff 145px);
        }
    }

    .web-transition {
        transition: 0.2s cubic-bezier(0.8, 0.2, 0.48, 1) background-color;
    }

    .icon {
        @apply inline-flex h-[14px] w-[14px] text-center align-middle font-normal [font-size:inherit];
    }

    .lg-thumb-item--has-video-button {
        /*position: relative;

        &:after {
            width: 0;
            height: 0;
            position: absolute;
            left: calc(50% - 6px);
            top: calc(50% - 6px);
            content: '';
            pointer-events: none;
            transform: rotate(-90deg);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 12px solid ${theme.color.white};
        }

        &:before {
            width: 50px;
            height: 30px;
            position: absolute;
            left: calc(50% - 25px);
            top: calc(50% - 15px);
            content: '';
            pointer-events: none;
            background-color: #ff0000;
            border-radius: 10px;
        }
    }*/
    }

    .smap {
        img:not(.icon):not(.responsive-image) {
            max-width: none;
        }

        .cluster > div {
            @apply box-content flex items-center justify-center space-x-1 !rounded-[9%] !border-0 !bg-[#da0000] text-center font-bold shadow-[5px_5px_#87888A];
            /* Necessary to override generated inline styles but keep size changes per amount of items in cluster */
            @apply aspect-[18/14] !h-auto scale-75;

            span {
                @apply text-h4 font-bold !leading-none text-white;
            }
            img {
                /* Hides pin icon in cluster */
                @apply hidden;
            }
        }

        .card {
            max-width: 200px;
            transform: translateY(-50px);
        }

        .smap-map-marker {
            width: 32px;
            height: 38px;

            cursor: pointer;
        }

        @screen lg {
            .card {
                max-width: none;
            }
        }
    }

    .aspect-ratio-fallback {
        @supports not (aspect-ratio: 1 / 1) {
            @apply before:float-left before:pt-[100%] before:content-[""];
        }
    }
}

.chat-button-container {
    @apply !hidden;
}

/** hides default spinbox */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'][type='number'] {
    -moz-appearance: textfield;
}

@layer components {
    .ui-link {
        @apply inline-flex cursor-pointer items-center bg-transparent text-primary underline outline-none hover:text-primary;

        img {
            margin-right: 5px;
            font-size: 0;
        }
        svg {
            margin-right: 5px;
            width: 17px;
            height: 17px;
        }
    }

    .ui-list-simple li {
        @apply relative mb-3 pl-5 text-body leading-5;

        &:before {
            @apply absolute top-1.5 left-0 h-1.5 w-1.5 bg-primary;
            content: '';
        }
    }

    .ui-list-ordered {
        & li {
            @apply relative mb-3 pl-7 text-body leading-5;
            counter-increment: item;

            &:before {
                @apply absolute top-0 left-0 h-0 w-0 bg-primary font-bold;
                content: counter(item) '.';
            }
        }
    }
}

@layer utilities {
    .instagram-gradient {
        background: linear-gradient(#f7f7f7, #f7f7f7) padding-box,
            linear-gradient(to right, #fec653 15%, #d83b78 50%, #624fc4 85%) border-box;
    }

    .menu-gradient {
        background: linear-gradient(to bottom, #222, #222 65px, #fff 65px, #fff 145px);
    }

    .gdpr-consent {
        label {
            span {
                @apply text-small;
            }
        }

        a {
            @apply text-inherit;
        }
    }
}
