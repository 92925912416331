@tailwind components;

@layer components {
    .slider-thumb {
        @apply pointer-events-none absolute top-6 h-0 w-full appearance-none outline-none;
        z-index: 3;
        -webkit-tap-highlight-color: transparent;

        &::-webkit-slider-thumb {
            @apply relative -mx-1.5 h-5 w-5 cursor-pointer appearance-none rounded-round border border-solid border-primary bg-white;
            -webkit-tap-highlight-color: transparent;
            pointer-events: all;
            z-index: 3;
        }

        &::-moz-range-thumb {
            @apply relative -mx-1.5 h-5 w-5 cursor-pointer rounded-round border border-solid border-primary bg-white;
            pointer-events: all;
            z-index: 3;
        }

        &::-ms-thumb {
            @apply -mx-1.5 h-5 w-5 cursor-pointer rounded-round border border-solid border-primary bg-white;
            z-index: 3;
        }

        &.slider-thumb-disabled::-webkit-slider-thumb,
        &.slider-thumb-disabled::-moz-range-thumb,
        &.slider-thumb-disabled::-ms-thumb {
            @apply bg-grayLight;
        }

        &::-webkit-slider-runnable-track,
        &::-moz-range-track,
        &::-ms-track,
        &::-ms-fill-lower,
        &::-ms-fill-upper {
            pointer-events: none;
        }
    }

    .limit-height {
        @apply relative -mx-5 px-5;

        &::after {
            @apply pointer-events-none absolute h-3/5;
            content: ' ';
            inset: auto 0 0 0;
            background: linear-gradient(#fff0, #ffff);
        }
    }

    .footer-user-text {
        @apply mb-10 max-w-full vl:mb-0 vl:max-w-[350px] vl:pr-10;

        ul li {
            @apply leading-none;

            &::after {
                top: calc(50% - 3px);
            }

            a {
                @apply no-underline;

                &:hover {
                    @apply underline;
                }
            }
        }
    }

    .overlay-wrapper {
        .overlay-enter {
            @apply opacity-0;
        }
        .overlay-enter-active {
            @apply opacity-100 transition-opacity;
        }
        .overlay-exit {
            @apply opacity-100;
        }
        .overlay-exit-active {
            @apply opacity-0 transition-opacity;
        }
    }

    .react-loading-skeleton {
        @apply rounded-md;
    }
}
