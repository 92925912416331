@tailwind components;

.Toastify__toast {
    padding: 15px 17px 15px 18px;
    border-radius: 7px;
}

.Toastify__toast-body {
    color: #0d0d0d;
}

.Toastify__close-button {
    align-self: center;
    opacity: 1;
    color: #0d0d0d;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: #c3e6fA;

    .Toastify__toast-icon {
        color: #3a8ff3;
    }

    .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
        background-color: #3a8ff3;
    }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #cce5df;

    .Toastify__toast-icon {
        color: #176652;
    }

    .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
        background-color: #176652;
    }
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: #ffe5c7;

    .Toastify__toast-icon {
        color: #ffb053;
    }

    .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
        background-color: #ffb053;
    }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: #edcad0;

    .Toastify__toast-icon {
        color: #bd0d2e;
    }

    .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
        background-color: #bd0d2e;
    }
}

:root {
    --toastify-toast-min-height: 48px;
    --toastify-toast-width: 400px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 10100;
}
