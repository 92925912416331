@tailwind components;

.table-styles {
    @apply w-full;

    tr {
        @apply border-t border-solid border-borderLight first:border-t-0 even:bg-grayLight;
    }

    td,
    th {
        @apply py-4 px-5;
    }

    td {
        @apply text-left text-small uppercase leading-4;
    }

    th {
        @apply text-right text-small font-bold leading-5;
    }
}
